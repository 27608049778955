import React, { useState } from "react";
import StateContext from "./context";
import { navigate } from "@gatsbyjs/reach-router";

const INITIAL_STATE = {
  imei: null,
  questions: [],
  answers: {},
  imeiInfo: {},
  paymentMethod: null,
  createImei: false,
  brands: [],
  partConditions: [],
  sessionId: null
};

const StateProvider = ({ children }) => {
  const [state, setState] = useState({ language: "el", ...INITIAL_STATE });

  const updateState = (addedState) => setState({ ...state, ...addedState });

  const setImei = (imei) => setState({ ...state, imei });

  const setCreateImei = (flag, brand) => {
    if (flag) {
      let date = new Date();
      let imei;
      if (brand === "xiaomi") {
        imei = `X${date.getDate()}${("0" + (date.getMonth() + 1)).slice(
          -2
        )}${date.getFullYear().toString().substring(2, 4)}${date
          .getTime()
          .toString()
          .substring(2, 14)}`;
      } else if (brand === "samsung") {
        imei = `S${date.getDate()}${("0" + (date.getMonth() + 1)).slice(
          -2
        )}${date.getFullYear().toString().substring(2, 4)}${date
          .getTime()
          .toString()
          .substring(2, 14)}`;
      }

      let deviceInfo = require("../../imeiInfoTemplate.json");
      setState({
        ...state,
        imei: imei,
        imeiInfo: deviceInfo,
        createImei: flag,
      });
    }
  };

  const addAnswer = (qid, answer) =>
    setState({
      ...state,
      answers: { ...state.answers, [qid]: answer },
    });

  const resetState = () =>
    setState({ language: state.language, ...INITIAL_STATE });

  const setPartConditions = (partConditions) =>
    setState({ ...state, partConditions });

  const setAssessmentResults = (assessmenResults) =>
    setState({ ...state, assessmenResults });

  const setQuestions = (questions) => setState({ ...state, questions });

  const setSessionId = (sessionId) => setState({ ...state, sessionId: sessionId });

  const setImeiInfo = (imeiInfo) => setState({ ...state, imeiInfo });

  const setOfferCoupon = (offer, coupon) =>
    setState({
      ...state,
      offer,
      clicked: 1,
      imeiInfo: { ...state.imeiInfo, coupon },
    });

  const setOffer = (offer) => {
    setState({ ...state, offer });
  };

  const setCondition = (condition) => setState({ ...state, condition });

  const setPromo = (code, price, offer) =>
    setState({ ...state, promo: { code, price }, offer });

  const setOfferCondition = (offer, condition) =>
    setState({ ...state, offer, condition });

  const setOfferForm = (offerForm, storeId) => {
    setState({ ...state, offerForm });
    const queryString = window.location.search;
    navigate(`/${storeId}/sign/${queryString}`);
  };

  const setPaymentMethod = (paymentMethod) =>
    setState({ ...state, paymentMethod });

  const setErrorMessage = (errorMessage) =>
    setState({ ...state, errorMessage });

  const setField = (fieldName, value) =>
    setState({ ...state, [fieldName]: value });

  const setBrands = (brands) => setState({ ...state, brands: brands });

  return (
    <StateContext.Provider
      value={{
        state,
        setPromo,
        setState,
        setPartConditions,
        setOfferCoupon,
        updateState,
        setImei,
        addAnswer,
        resetState,
        setQuestions,
        setImeiInfo,
        setOffer,
        setCondition,
        setOfferCondition,
        setOfferForm,
        setPaymentMethod,
        setField,
        setErrorMessage,
        setCreateImei,
        setBrands,
        setSessionId,
        setAssessmentResults
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
