import { Button } from "antd";
import styled from "styled-components";

const StyledButton = styled(Button)`
  height: 64px;
  font-size: 20px;
  font-weight: 600;
  background: #1a1a1a;
  border-radius: 32px;
  color: #ffffff;
  border: 0px;
`;

export default StyledButton;
