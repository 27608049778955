import Logo from "../assets/logo.png";
import { Link } from "@gatsbyjs/reach-router";
import { withTranslation } from "react-i18next";
import { Select } from "antd";
import { Row, Col, Button } from "react-bootstrap";

const PageContainer = ({
  children,
  noExit,
  progress,
  voucher = false,
  i18n,
}) => (
  <div id="pageContainer">
    <div
      id="progressBar"
      style={{ width: progress + "%" }}
      className="d-flex align-items-center justify-content-between"
    >
      <Col>
        <Row className="align-items-center">
          <div style={{ width: "56px" }} className="mx-3">
            <Link to={`/`} id="homepage">
              <img
                src="https://cdn.greenpanda.io/tradein-app/logos/orangepllogo.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1669730933498"
                alt="logo"
              />
            </Link>
          </div>
          <div
            style={{
              width: "86px",
              height: "35px",
              borderLeft: "1px solid #ffffff",
            }}
            className="px-3"
          >
            <img
              src="https://cdn.greenpanda.io/tradein-app/logos/poweredby.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1669730933298"
              alt="logo"
            />
          </div>
        </Row>
      </Col>
      <Col className="text-right align-items-center">
        <div style={{ width: "24px", display: "inline-block" }}>
          <img
            src="https://cdn.greenpanda.io/tradein-app/language-icon.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1668590123950"
            alt="logo"
          />
        </div>
        <Select
          defaultValue={i18n.language}
          onChange={(val) => i18n.changeLanguage(val)}
        >
          <Select.Option value="el">EL</Select.Option>
          <Select.Option value="en">EN</Select.Option>
        </Select>
        <Link to={`/logout`} id="logout">
          <Button
            style={{ color: "white", background: "#1a1a1a", border: "none" }}
          >
            Logout
          </Button>
        </Link>
      </Col>
      {/* <div id="whiteContainer" className="container"> */}
    </div>

    <div id="whiteBody">{children}</div>
  </div>
);
export default withTranslation()(PageContainer);
