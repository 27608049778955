import "./App.scss";
import { Router } from "@gatsbyjs/reach-router";
import StateProvider from "./providers/state/provider";
import Header from "./components/header";

// import Home from './routes/home';
// import Imei from './routes/imei';
// import Question from './routes/question';
// import Offer from './routes/offer';

// import OfferAcceptForm from './routes/offerAcceptForm';
// import Sign from './routes/sign';

// import ThankYou from './routes/thankyou';
// import ErrorPage from './routes/error';

// import LogIn from './routes/pinInput';
// import Logout from './routes/logout';
import NotFound from "./routes/notfound";

import Login from "./pages/Login";
import Logout from "./pages/Logout";

import Home from "./pages/Home";
import Question from "./pages/Question";
import Offer from "./pages/Offer";
import PaymentMethods from "./pages/PaymentMethods";
import Form from "./pages/Form";
import Sign from "./pages/Sign";
import Voucher from "./pages/Voucher";
import Error from "./pages/Error";
import Conditions from "./pages/Conditions";
import WhitePage from "./pages/WhitePage";
import ThankYou from "./pages/ThankYou";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style.scss";

function App() {
  return (
    <div className="App">
      <StateProvider>
        <Router style={{ height: "100%" }}>
          {/* <Login path="/login" /> */}

          <Login path="/" />
          <Login path="/login" />
          <Logout path="/logout" />
          <WhitePage path="/whitepage" />
          <Home path="/:storeId" />
          <ThankYou path="/thankyou" />
          <Voucher path="/voucher" />

          <Header
            style={{ background: "white", height: "100%" }}
            path="/:storeId"
          >
            <Home path=":category" />
            <Question path="question/:qid" />
            <Conditions path="conditions" />
            <Offer path="offer" />
            <PaymentMethods path="paymentMethods" />
            <Form path="form" />
            <Sign path="sign" />
            <Error path="error" />
            {/* 
            // 
            // <ErrorPage path="error" />
            <LogIn path="log-in" /> */}
            <NotFound default />
          </Header>
          <NotFound default />
        </Router>
      </StateProvider>
    </div>
  );
}

export default App;
