import React from "react";
import { Link } from "@gatsbyjs/reach-router";
import { Result } from "antd";
import PageContainer from "../../containers/PageContainer";

const NotFound = () => {
  return (
    <PageContainer>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/" className="primaryButton button">
            Back Home
          </Link>
        }
      />
    </PageContainer>
  );
};

export default NotFound;
