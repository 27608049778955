import React, { useState, useEffect, useContext, useCallback } from "react";
import { navigate } from "@gatsbyjs/reach-router";
import { Space, Select } from "antd";
import StateContext from "../providers/state";
import { withTranslation } from "react-i18next";
import PageContainer from "../containers/PageContainer";
import { Form, Alert, Button, Spinner } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import iconQuote from "../assets/icon-quote.svg";
import QRCode from "react-qr-code";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "Home",
  },
};

const Home = ({ storeId, category = "", t, i18n }) => {
  const [loadingQ, setLoadingQ] = useState(localStorage.getItem("storeId"));
  const [partner, setPartner] = useState(localStorage.getItem("partner"));
  const { setQuestions } = useContext(StateContext);
  const errorjson = require("../errorCodes.json");
  const { state, setImei, setCreateImei, updateState, setAssessmentResults } =
    useContext(StateContext);
  const [error, setError] = useState(false);
  if (!category) {
    category = "smartphone";
  }
  TagManager.dataLayer(tagManagerArgs);
  const [loading, setLoading] = useState();
  const { imei } = state;
  const errorText = t("home.error");
  sessionStorage.setItem("category", category);

  const includeParts = [
    "back_camera",
    "biometrics",
    "digitizer",
    "front_camera",
    "sound",
  ];

  useEffect(() => {
    const storeId = localStorage.getItem("storeId");
    if (!storeId) {
      navigate("/login");
      return;
    }
    if (storeId.toLowerCase() === "wind1") {
      navigate(`/voucher`);
      return;
    }
    // navigate(`/${storeId}`);
  }, []);

  useEffect(() => {
    if (loadingQ && !!localStorage.getItem("storeId")) {
      // fetch('http://server.greenpanda.io:3001/api/questions')
      fetch("https://server.greenpanda.io/scripts/getQuestions.php")
        .then((response) => response.json())
        .then((json) => {
          setLoadingQ(false);
          console.log("questions", json);
          setQuestions(json);
        })
        .catch((error) => {
          setLoadingQ(false);
        });
    }
  }, [loading, setQuestions]);
  // https://codesandbox.io/s/n5j7knjo54?file=/src/index.js:89-107

  const onImeiSend = useCallback(async () => {
    if (!imei || typeof imei !== "string") {
      return;
    }
    setLoading(true);
    let deviceType = category != "" ? category : "smartphone";
    state.deviceType = deviceType;

    const data = {};
    data["imei"] = imei;

    try {
      const response = await fetch(
        `https://imei-service.greenpanda.io/device-assessment?imei=${imei}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const assessment = await response.json();

      if (Object.keys(assessment).length === 0) {
        setLoading(false);
        throw new Error(
          "Self diagnostic not found. Please ask customer to run a new session of self assesments through web app by scanning QR code below"
        );
      } else if (
        Math.abs((new Date(assessment.timestamp) - Date.now()) / 86400000) >= 7
      ) {
        setLoading(false);
        throw new Error(
          "Self diagnostic checks are outdated. Please ask customer to run a new session of self assesments through web app by scanning QR code below"
        );
      }

      const responseOffer = await fetch(
        `https://imei-service.greenpanda.io/v2/offer`,
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imei: imei,
            deviceType: deviceType,
            currencies: "EUR",
            partner: "pandas",
          }),
        }
      );

      const deviceInfo = await responseOffer.json();

      if (deviceInfo.error) {
        setLoading(false);
        throw new Error(errorjson[deviceInfo.error]);
      } else {
        let arr = [];
        for (let part in assessment) {
          if (includeParts.includes(part)) {
            let obj = { code: part, condition: assessment[part] };
            arr.push(obj);
          }
        }

        setLoading(false);
        updateState({
          sessionId: assessment.sessionId,
          imeiInfo: { ...deviceInfo },
          assessmentResults: arr,
        });

        navigate(`/${storeId}/conditions`);
      }
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  }, [imei, storeId]);

  const onImeiCreate = (brand) => {
    setCreateImei(true, brand);
    navigate(`/${storeId}/offer`);
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    setImei(input);
    setError(false);
  };

  if (loadingQ) {
    return (
      <div id="overlayCenter">
        <Space size="large">
          <Spinner animation="border" className="mr-3" />
        </Space>
      </div>
    );
  }

  return (
    <PageContainer progress="100" noExit voucher>
      <img src={iconQuote} alt="icon-quote" style={{ maxHeight: "130px" }} />
      <div style={{ width: "560px", textAlign: "center" }}>
        {(partner.includes("istorm") || partner.includes("greenpanda")) && (
          <ul className="nav nav-tabs" style={{ margin: "0 0 40px 0" }}>
            <li
              className="nav-item"
              onClick={() => {
                sessionStorage.setItem("category", "smartphone");
              }}
            >
              {console.log(category)}
              {category !== "smartphone" ? (
                <a href="/" className="nav-link" style={{ color: "black" }}>
                  iPhones
                </a>
              ) : (
                <a
                  href="/"
                  className="nav-link unclickable active"
                  style={{ color: "#41d900", fontWeight: "500" }}
                >
                  iPhones
                </a>
              )}
            </li>
            <li
              className="nav-item"
              onClick={() => {
                sessionStorage.setItem("category", "ipad");
              }}
            >
              {category !== "ipad" ? (
                <a
                  href={"/" + storeId + "/ipad"}
                  className="nav-link"
                  style={{ color: "black" }}
                >
                  iPads
                </a>
              ) : (
                <a
                  href={"/" + storeId + "/ipad"}
                  className="nav-link unclickable active"
                  style={{ color: "#41d900", fontWeight: "500" }}
                >
                  iPads
                </a>
              )}
            </li>
            {partner.includes("istorm") &&
              process.env.REACT_APP_SMARTWATCH_ENABLED.toLowerCase() ===
                "true" && (
                <li
                  className="nav-item"
                  onClick={() => {
                    sessionStorage.setItem("category", "smartwatch");
                  }}
                >
                  {category !== "smartwatch" ? (
                    <a
                      href={"/" + storeId + "/smartwatch"}
                      className="nav-link"
                      style={{ color: "black" }}
                    >
                      Watches
                    </a>
                  ) : (
                    <a
                      href={"/" + storeId + "/smartwatch"}
                      className="nav-link unclickable active"
                      style={{ color: "#41d900", fontWeight: "500" }}
                    >
                      Watches
                    </a>
                  )}
                </li>
              )}
          </ul>
        )}

        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <h1
            className="title text-center mt-3"
            onClick={() => {
              navigator.clipboard.writeText(357296092404683);
            }}
          >
            {t("home.title")}
          </h1>
          {!category && <h4 className="mb-4">{t("home.description")}</h4>}
          {category && <h4 className="mb-4">{t("home.descriptionIpad")}</h4>}

          <Form.Group controlId="formBasicCheckbox">
            <Form.Control
              type="text"
              name="imei"
              pattern="^[a-zA-Z0-9_.-]*$"
              value={imei || ""}
              disabled={loading}
              onChange={onChangeInput}
              maxLength="15"
              size="large"
              style={{
                fontSize: "25px",
                textAlign: "center",
                background: "#F7F7F7",
                border: `${!!error ? " 2px solid #ff6600" : ""}`,
              }}
            />
          </Form.Group>
          {!!error && (
            <div
              style={{
                color: "#ff6600",
                textAlign: "left",
                fontStyle: "italic",
              }}
            >
              {error}
            </div>
          )}
          <div
            style={{ color: "#808080", fontSize: "20px", fontWeight: "500" }}
            className="d-flex align-items-center justify-content-center"
          >
            {t("home.scan")}{" "}
            {/* <OverlayTrigger
              style={{ background: "#E6E6E6" }}
              key={"right"}
              placement={"right"}
              overlay={
                <Tooltip id={`tooltip-${"right"}`}>
                  Customers with outdated or non-completed self-diagnostic
                  checks should scan the QR code to take/retake the self
                  assessment diagnostics.
                </Tooltip>
              }
            >
              <div style={{ width: "24px" }}>
                <img
                  src="https://cdn.greenpanda.io/tradein-app/info.svg"
                  alt="info"
                  width="24"
                />
              </div>
            </OverlayTrigger> */}
            <div
              class="myDIV2"
              style={{ position: "relative", marginLeft: "5px" }}
            >
              <img
                src="https://cdn.greenpanda.io/tradein-app/info.svg"
                alt="info"
                width="30"
                className="tooltip-image myDiv2"
              />
            </div>
            <div class="hide2 battery">
              <p className="m-0">
                Customers can scan the QR code here in order to run self
                diagnostic checks on their device. Alternatively they can just
                type the url https://orangepl-tradein-fromstore.pandas.io on a
                web browser
              </p>
            </div>
          </div>
          <div style={{ margin: "30px auto 10px", width: "130px" }}>
            <QRCode
              size={130}
              value={`https://orangepl-tradein-fromhome.pandas.io/`}
            />
          </div>
          <div
            style={{
              color: "rgba(128, 128, 128, 1)",
              fontSize: "17px",
              margin: "20px 0 50px",
            }}
          >
            <p className="m-0">QR redirects to</p>{" "}
            <p>
              <a
                style={{
                  color: "#1a1a1a",
                  fontWeight: "600",
                  textDecoration: "underline",
                }}
                href="https://orangepl-tradein-fromstore.pandas.io"
              >
                https://orangepl-tradein-fromstore.pandas.io
              </a>
            </p>
          </div>
          <Button
            className="primaryButton button"
            size="small"
            type="submit"
            disabled={loading || !imei}
            onClick={onImeiSend}
          >
            {loading && <Spinner animation="border" className="mr-3" />}
            <span>{t("home.cta")}</span>
          </Button>

          {storeId.toLowerCase().includes("scooter") && (
            <Button
              className="samsungButton button"
              size="small"
              type="submit"
              disabled={loading || imei}
              onClick={() => onImeiCreate("samsung")}
            >
              <span>{t("home.samsungImeiCreate")}</span>
            </Button>
          )}
          {storeId.toLowerCase().includes("scooter") && (
            <Button
              className="xiaomiButton button"
              size="small"
              type="submit"
              disabled={loading || imei}
              onClick={() => {
                onImeiCreate("xiaomi");
              }}
            >
              <span>{t("home.xiaomiImeiCreate")}</span>
            </Button>
          )}
        </Form>
      </div>
    </PageContainer>
  );
};

export default withTranslation()(Home);
