import React, { useContext } from "react";
import { Redirect, Link } from "@gatsbyjs/reach-router";
import StateContext from "../providers/state";
import { withTranslation } from "react-i18next";
import PageContainer from "../containers/PageContainer";

const Question = ({ qid, storeId, i18n }) => {
  const { addAnswer, state: { questions: { questions }, language } = {} } =
    useContext(StateContext);

  // console.log({qid}, questions)
  if (!qid || !questions || !questions[qid - 1]) {
    return <Redirect noThrow to={`/${storeId}`} />;
  }

  // console.log({qid, qLength: questions.length, hasNext: qid < (questions.length)})

  const {
    text: questionLanguages,
    description: descriptionLanguages,
    answers,
    id,
    next,
  } = questions[qid - 1] || {};
  const question = questionLanguages[i18n.language];
  const description = descriptionLanguages[i18n.language];
  const hasNext = parseInt(qid) < questions.length;
  const toLink = hasNext
    ? `/${storeId}/question/${parseInt(qid) + 1}`
    : `/${storeId}/offer`;
  const progressPercentage = ((qid * 100) / questions.length) | 0;

  // console.log(answers[1])
  return (
    <PageContainer progress={progressPercentage}>
      <h3 className=" text-center title mt-4"> {question} </h3>
      <h5 className="mb-5 text-center" style={{ maxWidth: "70%" }}>
        {" "}
        {description.substr(0, 200)}...{" "}
      </h5>
      <Link
        id={qid + "2"}
        to={toLink}
        className={`${answers[1].grade} secondaryButton button`}
        onClick={() => addAnswer(qid, 1)}
      >
        {answers[1].text[i18n.language]}
      </Link>
      <Link
        id={qid + "1"}
        to={toLink}
        className={`${answers[0].grade} secondaryButton button`}
        onClick={() => addAnswer(qid, 0)}
      >
        {answers[0].text[i18n.language]}
      </Link>
    </PageContainer>
  );
};

export default withTranslation()(Question);
