import React, { useContext, useEffect, useState } from "react";
import StateContext from "../providers/state";
import { navigate, Redirect } from "@gatsbyjs/reach-router";
import { withTranslation } from "react-i18next";
import PageContainer from "../containers/PageContainer";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import styled from "styled-components";
import QRCode from "react-qr-code";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "Conditions",
  },
};

const BackButton = styled.div`
  position: absolute;
  left: 20px;
  top: 150px;
  font-size: 18px;
  font-weight: 800;
  &:hover {
    cursor: pointer;
  }
`;

const Conditions = ({ storeId, t, i18n }) => {
  TagManager.dataLayer(tagManagerArgs);
  const { state, setImeiInfo, setPartConditions, setErrorMessage } =
    useContext(StateContext);
  const CDN = "https://cdn.greenpanda.io";
  const [loading, setLoading] = useState();
  const { imeiInfo, imei } = state;
  const [parts, setParts] = useState("");
  const [disableButton, setdisableButton] = useState(true);
  const errorjson = require("../errorCodes.json");
  const [selectedConditions, setSelectedConditions] = useState([]);
  const cosmeticsMapping = {
    no_light_scratches: 0.75,
    deep_scratches: 0.5,
    cracks: 0.25,
    yes: 1.0,
    no: 0.0,
    titanium: "titanium",
    stainless_steel: "stainless_steel",
    aluminum: "aluminum",
  };

  const partConditionMapping = {
    phone_battery: {
      "100-96": 100,
      "95-87": 90,
      "Below 86": 85,
    },

    phone_front_cosmetics: {
      yes: 1.0,
      no: 0.25,
    },

    phone_back_cosmetics: {
      yes: 1.0,
      no: 0.25,
    },

    phone_oem_battery: {
      yes: 1.0,
      no: 0.0,
    },

    phone_lcd_without_blemishes: {
      yes: 1.0,
      no: 0.0,
    },
  };

  const exludeParts = [
    "phone_camera_cosmetics",
    "phone_camera_works",
    "phone_buttons_work",
    "phone_bluetooth",
    "phone_wifi",
    "phone_digitizer",
    "phone_sound",
    "phone_biometrics",
    "phone_front_camera",
    "phone_back_camera",
    "phone_screen",
    "phone_body_frame",
    "phone_oem_screen",
  ];

  const category = sessionStorage.getItem("category");
  // const category = 'smartphone';
  if (imeiInfo?.brand?.toLowerCase() !== "apple") {
    exludeParts.push("phone_battery", "phone_oem_battery");
  }
  useEffect(() => {
    fetch(
      `https://imei-service.greenpanda.io/v1/parts/definitions?category=${category}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error || data.parts === []) {
          setErrorMessage(errorjson["genericError"]);
          navigate(`/${storeId}/error`);
        } else {
          setParts(data.parts);
        }
      })
      .catch((e) => {
        console.log("eskase", e);
        // setLoading(false);
        // setError(errorText);
      });
  }, []);

  const handleBtn = (condition, data) => {
    let updatedValue = { code: condition, condition: data };
    let newState = [...selectedConditions];
    let found = false;
    console.log(selectedConditions);
    let index;
    for (let key in newState) {
      if (newState[key].code === condition) {
        found = true;
        index = key;
      }
    }
    if (found) {
      newState[index] = updatedValue;
      setSelectedConditions(newState);
    } else {
      setSelectedConditions((prevState) => [...prevState, updatedValue]);
    }
  };

  useEffect(() => {
    setPartConditions(selectedConditions);
  }, [selectedConditions]);

  const handleSubmit = async () => {
    let partner = localStorage.getItem("partner");
    if (partner === "greenpanda") {
      partner = "pandas";
    }
    setLoading(true);

    let arr = [];
    let deviceAssessmentData = {};
    // const mathRandom = Math.random().toString(36).slice(2); // create session id

    for (let key in selectedConditions) {
      if (!exludeParts.includes(selectedConditions[key].code)) {
        let part = selectedConditions[key].code.split("_");
        part.shift();
        for (var i = 1; i < part.length; i++) {
          part[i] = part[i].charAt(0).toUpperCase() + part[i].substr(1);
        }
        part = part.join("");
        if (part === "battery") {
          part = "batteryHealth";
        }
        deviceAssessmentData[part] =
          partConditionMapping[selectedConditions[key].code][
            selectedConditions[key].condition
          ];
      }
    }

    if (imeiInfo?.brand?.toLowerCase() === "apple") {
      deviceAssessmentData["oemScreen"] = deviceAssessmentData["oemBattery"];
    }

    deviceAssessmentData["brand"] = imeiInfo?.brand;
    deviceAssessmentData["model"] = imeiInfo?.modelName;
    deviceAssessmentData["manufacturer"] = imeiInfo?.manufacturer;
    deviceAssessmentData["category"] = category;

    deviceAssessmentData["imei"] = imei;
    deviceAssessmentData["sessionId"] = state.sessionId;
    console.log("deviceAssessmentData", deviceAssessmentData);

    const asessmentResponse = await fetch(
      "https://imei-service.greenpanda.io/device-assessments",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(deviceAssessmentData),
      }
    );

    const offerData = {};
    offerData["deviceType"] = category;
    offerData["partner"] = "orangepl";
    offerData["imei"] = imei;
    offerData["currencies"] = "PLN";

    console.log('offer', offerData)

    const offerResponse = await fetch(
      `https://imei-service.greenpanda.io/v2/offer/`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(offerData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setImeiInfo(data);
        navigate(`/${storeId}/offer`);
      });
  };

  useEffect(() => {
    let arr = [];
    for (let key in parts) {
      if (!exludeParts.includes(parts[key].code)) {
        let obj = { code: parts[key].code, condition: "" };
        arr.push(obj);
      }
    }
    setSelectedConditions(arr);
  }, [parts]);

  useEffect(() => {
    let hasEmptyValue = false;
    for (let part in selectedConditions) {
      if (selectedConditions[part].condition === "") {
        hasEmptyValue = true;
        break;
      }
    }
    if (hasEmptyValue) {
      setdisableButton(true);
    } else {
      setdisableButton(false);
    }
  }, [selectedConditions]);

  return (
    <>
      {!imeiInfo || Object.keys(imeiInfo).length === 0 ? (
        <Redirect to={`/${storeId}`} />
      ) : (
        <PageContainer hasExit>
          <Row
            className="justify-content-center"
            style={{ marginBottom: "160px" }}
          >
            <BackButton
              onClick={() => {
                navigate(`/${storeId}/`);
              }}
            >
              {"<Back"}
            </BackButton>
            <div
              style={{
                background: "#DAF1DE",
                border: "1px solid #91D49B",
                padding: "25px 60px",
                margin: "-30px 0 30px 0",
              }}
            >
              <p className="m-0" style={{ color: "rgba(43, 110, 53, 1)" }}>
                Device and web app diagnostic results have been successfully
                identified.
              </p>
            </div>
            <Col xs="12">
              <div className="text-center mb-5">
                <div>
                  <h4 id="offerModel">
                    {imeiInfo.brand} {imeiInfo.model}{" "}
                    <span style={{ fontWeight: "300" }}>
                      {imeiInfo?.capacity}
                    </span>
                  </h4>
                </div>
                <p style={{ color: "rgba(128,128,128", marginTop: "10px" }}>
                  Please answer last questions below to generate final offer for
                  customer
                </p>
              </div>
            </Col>
            <Col xs="12">
              <div className="text-center mb-5">
                <div>
                  {selectedConditions &&
                    selectedConditions.map((part, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex justify-content-between align-items-center m-auto"
                          style={{
                            borderTop: "#E6E6E6 solid 1px",
                            padding: "20px 0",
                            maxWidth: "850px",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <img
                              style={{ marginRight: "10px" }}
                              src={CDN + `/tradein-app/${part.code}.svg`}
                              alt="condition"
                              width="30"
                              height="30"
                            />
                            {part.code === "phone_back_cosmetics" ||
                            part.code === "phone_front_cosmetics" ||
                            part.code === "phone_lcd_without_blemishes" ? (
                              <div
                                className="text-left"
                                style={{ paddingTop: "0px" }}
                              >
                                <p
                                  style={{
                                    marginTop: "17px",
                                    marginBottom: "0",
                                  }}
                                  className=""
                                >
                                  {t(`conditions.${part.code}`)}
                                </p>
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "rgba(128,128,128,1",
                                  }}
                                  className="m-0"
                                >
                                  {t(`conditions.${part.code}_subtext`)}
                                </p>
                              </div>
                            ) : (
                              <p className="m-0 " style={{ fontWeight: "600" }}>
                                {t(`conditions.${part.code}`)}
                              </p>
                            )}

                            {part.code === "phone_lcd_without_blemishes" && (
                              <div style={{ marginLeft: "60px" }}>
                                <QRCode
                                  style={{ marginRight: "5vw" }}
                                  size={70}
                                  value={`${window.location.protocol}//${window.location.hostname}/whitepage`}
                                />
                              </div>
                            )}
                          </div>

                          {part.code !== "phone_battery" && (
                            <div
                              className="d-flex align-items-center"
                              style={{ position: "relative" }}
                            >
                              <div
                                onClick={() => handleBtn(part.code, "yes")}
                                key={"yes"}
                                className="partCondition"
                              >
                                {selectedConditions[index]?.condition ===
                                "yes" ? (
                                  <div
                                    style={{
                                      background: "rgba(187,255,204,0.5)",
                                      padding: "20px 30px",
                                    }}
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        cursor: "pointer",
                                        width: "90%",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {t(`conditions.yes`)}
                                    </p>
                                  </div>
                                ) : (
                                  <div style={{ padding: "20px 30px" }}>
                                    <p
                                      style={{ cursor: "pointer" }}
                                      className="m-0"
                                    >
                                      {t(`conditions.yes`)}
                                    </p>
                                  </div>
                                )}
                              </div>

                              <div
                                onClick={() => handleBtn(part.code, "no")}
                                key={"no"}
                                className="partCondition"
                              >
                                {selectedConditions[index]?.condition ===
                                "no" ? (
                                  <div
                                    style={{
                                      background: "rgba(255,75,85,0.5)",
                                      padding: "20px 30px",
                                    }}
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {t(`conditions.no`)}
                                    </p>
                                  </div>
                                ) : (
                                  <div style={{ padding: "20px 30px" }}>
                                    <p
                                      style={{ cursor: "pointer" }}
                                      className="m-0"
                                    >
                                      {t(`conditions.no`)}
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div className="myDIV">
                                <img
                                  src="https://cdn.greenpanda.io/tradein-app/info.svg"
                                  alt="info"
                                  width="30"
                                  className="tooltip-image myDiv"
                                />
                              </div>
                              <div className="hide">
                                <p
                                  className="m-0"
                                  dangerouslySetInnerHTML={{
                                    __html: t(`tooltip.${part.code}`),
                                  }}
                                ></p>
                                {part.code === "phone_oem_battery" && (
                                  <img
                                    src="https://cdn.greenpanda.io/tradein-app/conditions/info-image.png"
                                    alt="OEM info"
                                    width="150px"
                                    className="mt-4"
                                  />
                                )}
                              </div>
                            </div>
                          )}

                          {part.code === "phone_battery" && (
                            <div className="d-flex align-items-center">
                              <div
                                onClick={() => handleBtn(part.code, "100-96")}
                                key={"A"}
                                className="partCondition"
                              >
                                {selectedConditions[index]?.condition ===
                                "100-96" ? (
                                  <div
                                    style={{
                                      background: "rgba(187,255,204,0.5)",
                                      padding: "20px 30px",
                                    }}
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {t(`conditions.A`)}
                                    </p>
                                  </div>
                                ) : (
                                  <div style={{ padding: "20px 30px" }}>
                                    <p
                                      style={{ cursor: "pointer" }}
                                      className="m-0"
                                    >
                                      {t(`conditions.A`)}
                                    </p>
                                  </div>
                                )}
                              </div>

                              <div
                                onClick={() => handleBtn(part.code, "95-87")}
                                key={"B"}
                                className="partCondition"
                                style={{
                                  borderRight:
                                    "rgba(0, 0, 0, 0.05) 2.5px solid",
                                }}
                              >
                                {selectedConditions[index]?.condition ===
                                "95-87" ? (
                                  <div
                                    style={{
                                      // background: "rgba(255,239,93,0.5)",
                                      background: "rgba(255, 239, 93, 0.5)",
                                      padding: "20px 30px",
                                    }}
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {t(`conditions.B`)}
                                    </p>
                                  </div>
                                ) : (
                                  <div style={{ padding: "20px 30px" }}>
                                    <p
                                      style={{ cursor: "pointer" }}
                                      className="m-0"
                                    >
                                      {t(`conditions.B`)}
                                    </p>
                                  </div>
                                )}
                              </div>

                              <div
                                onClick={() => handleBtn(part.code, "Below 86")}
                                key={"C"}
                                className="partCondition"
                              >
                                {selectedConditions[index]?.condition ===
                                "Below 86" ? (
                                  <div
                                    style={{
                                      background: "rgba(255,75,85,0.5)",
                                      padding: "20px 30px",
                                    }}
                                  >
                                    <p
                                      className="m-0"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {t(`conditions.C`)}
                                    </p>
                                  </div>
                                ) : (
                                  <div style={{ padding: "20px 30px" }}>
                                    <p
                                      style={{ cursor: "pointer" }}
                                      className="m-0"
                                    >
                                      {t(`conditions.C`)}
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div
                                class="myDIV"
                                style={{ position: "relative" }}
                              >
                                <img
                                  src="https://cdn.greenpanda.io/tradein-app/info.svg"
                                  alt="info"
                                  width="30"
                                  className="tooltip-image myDiv"
                                />
                              </div>
                              <div class="hide battery">
                                <p
                                  className="m-0"
                                  dangerouslySetInnerHTML={{
                                    __html: t(`tooltip.${part.code}`),
                                  }}
                                ></p>
                                {part.code === "phone_oem_battery" && (
                                  <img
                                    src="https://cdn.greenpanda.io/tradein-app/conditions/info-image.png"
                                    alt="OEM info"
                                    width="150px"
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </Col>
            <Button
              size="small"
              type="submit"
              onClick={() => handleSubmit()}
              className={
                disableButton
                  ? "disabled primaryButton button"
                  : "primaryButton button"
              }
              disabled={disableButton || loading}
            >
              {loading && <Spinner animation="border" className="mr-3" />}
              {t("conditions.button")}
            </Button>
          </Row>
        </PageContainer>
      )}
    </>
  );
};

export default withTranslation()(Conditions);
