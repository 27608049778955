import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import StateContext from "../providers/state";
import { withTranslation } from "react-i18next";
import Button from "../components/button";
import PageContainer from "../containers/PageContainer";
import { navigate } from "@gatsbyjs/reach-router";

import { Title } from "../components/typography";
import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "Form",
  },
};

const NUMERIC_ONLY = /^[0-9\b]+$/;
const phoneRegex =
  /^[?00|?\+]*(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/i;

const OfferPill = styled.div`
  border-bottom: 2px solid #f2f2f2;
  padding: 0 10px 0 20px;
  text-align: center;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 95%;
  margin-bottom: 30px;
`;

const Price = styled.div`
  border: solid 4px #3ac93a;
  border-radius: 80px 80px;
  padding: 0 10px;
  margin-left: 30px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 60px;
`;

const BackButton = styled.div`
  position: absolute;
  left: 20px;
  font-size: 16px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  background: #eceff2;
  border-radius: 10px;
  color: #04e502;
  border: solid 2px #d6dfe6;
  padding: 0 20px;
  font-size: 30px;
  color: #263846;
  outline: none;
  height: 64px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const StyledForm = styled(Form)`
  max-width: 550px;
  width: 100%;

  .ant-form-item-control {
    width: 100%;
  }
  .ant-form-item {
    display: flex;
    flex-direction: column !important;
    width: 100%;
  }
  .ant-row {
    display: flex;
    flex-direction: column !important;
  }
  .ant-form-item-label {
    text-align: left;
  }
  label {
    color: #1a1a1a;
    font-size: 16px;
    margin: 0;
  }
`;

const GPForm = ({ storeId, t, i18n, onSubmit }) => {
  TagManager.dataLayer(tagManagerArgs);
  const errorjson = require("../errorCodes.json");
  const [form] = useForm();
  const { state, setOfferForm, setErrorMessage } = useContext(StateContext);
  const { imeiInfo, offer, paymentMethod } = state;

  useEffect(() => {
    if (imeiInfo && (imeiInfo.fmiOn || imeiInfo.fmiON)) {
      setErrorMessage(errorjson["Find my iPhone is on"]);
      navigate(`/${storeId}/error`);
    }
  }, []);

  console.log(imeiInfo);
  const onFinish = (values) => {
    console.log("***", values);
    setOfferForm({ ...values }, storeId);
  };

  return (
    <PageContainer>
      <OfferPill>
        <BackButton
          onClick={() => {
            navigate(`/${storeId}/offer`);
          }}
        >
          {"<Back"}
        </BackButton>
        {imeiInfo.name} - <b>Voucher {offer}zł</b>
      </OfferPill>
      <h3 className="mb-4 title">
        {/* Please fill in your information */}
        {t("form.title")}
      </h3>

      <StyledForm
        // {...layout}
        form={form}
        name="offer"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          label={t("form.name")}
          name="name"
          value=""
          rules={[{ required: false, message: t("form.nameError") }]}
        >
          <StyledInput />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          value=""
          rules={[
            { required: false, type: "email", message: t("form.emailError") },
          ]}
        >
          <StyledInput />
        </Form.Item>

        <Form.Item
          label={t("form.tel")}
          name="tel"
          value=""
          rules={[
            {
              required: false,
              pattern: phoneRegex,
              message: t("form.telError"),
            },
          ]}
        >
          <StyledInput />
        </Form.Item>

        {paymentMethod === "iban" && (
          <Form.Item
            label="IBAN"
            name="iban"
            rules={[{ required: false, message: t("form.ibanError") }]}
          >
            <StyledInput />
          </Form.Item>
        )}
        {paymentMethod === "card" && (
          <Form.Item
            label={t("form.card")}
            name="card"
            rules={[
              {
                required: false,
                pattern: NUMERIC_ONLY,
                message: t("form.cardError"),
              },
            ]}
          >
            <StyledInput />
          </Form.Item>
        )}
        <Button
          size="large"
          className="primaryButton button mt-5"
          // onClick={onImeiSend}
          type="submit"
          htmlType="submit"
        >
          {t("form.button")}
        </Button>
      </StyledForm>
    </PageContainer>
  );
};

export default withTranslation()(GPForm);
