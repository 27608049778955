import React, { useContext, useEffect, useState } from "react";
import StateContext from "../providers/state";
import { navigate, Redirect } from "@gatsbyjs/reach-router";
import { withTranslation } from "react-i18next";
import PageContainer from "../containers/PageContainer";
import { Row, Col, Button, Spinner, Alert, Form } from "react-bootstrap";
import { Radio, Space } from "antd";
import Mobile from "../assets/mobile.png";
import Broken from "../assets/broken.png";
import styled from "styled-components";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "Offer",
  },
};

const BackButton = styled.div`
  position: absolute;
  left: 20px;
  font-size: 16px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
`;

const Offer = ({ storeId, t, i18n }) => {
  const errorjson = require("../errorCodes.json");
  const {
    state,
    setOfferCondition,
    setPaymentMethod,
    setOfferCoupon,
    setErrorMessage,
    setPromo,
    setImeiInfo,
    setBrands,
  } = useContext(StateContext);
  TagManager.dataLayer(tagManagerArgs);
  const partner = localStorage.getItem("partner");
  const [error, setError] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoAmount, setPromoAmount] = useState();
  const [alreadyUsed, setAlreadyUsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disableText, setDisableText] = useState(false);
  const [valid, setValid] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const {
    questions: { questions },
    answers,
    imeiInfo,
  } = state;
  imeiInfo.fmiOn = false; //////////////////////////////////////////////////////////////////////////
  const condition = imeiInfo.condition;
  const [offer, setOffer] = useState(
    imeiInfo.localPrices
      ? Math.round(imeiInfo.localPrices[0].price * 1.4)
      : Math.round(imeiInfo.price * 1.4)
  );
  const [couponApplied, setCouponApplied] = useState(false);
  const coupon = imeiInfo.localCoupons
    ? imeiInfo.localCoupons[0].price
    : imeiInfo.coupon;
  const errorText = t("home.error");
  const [samsungPromo, setSamsung] = useState(false);
  const [xiaomiPromo, setXiaomi] = useState(false);
  const [youPromo, setYou] = useState(false);
  const [pandasPromo, setPandas] = useState(false);
  const selectedConditions = state.partConditions;
  const category = sessionStorage.getItem("category");
  const CDN = "https://cdn.greenpanda.io";
  const modelImage = replaceSpecialChars(imeiInfo.model?.toLowerCase());

  useEffect(() => {
    setOfferCondition(offer + coupon, condition);
  }, []);
  useEffect(() => {
    if (offer === null || offer === undefined) {
      navigate(`/${storeId}`);
    }
  }, [offer]);
  const onOfferChange = (e) => {
    if (e.target.value == offer + coupon) {
      setOfferCoupon(e.target.value, coupon);
    } else {
      setOfferCoupon(e.target.value, null);
    }
  };
  useEffect(() => {
    if (state.clicked) {
      console.log("Changed", state.offer);
      if (imeiInfo && (imeiInfo.fmiOn || imeiInfo.fmiON)) {
        setErrorMessage(errorjson["Find my iPhone is on"]);
        navigate(`/${storeId}/error`);
      }
      if (localStorage.getItem("voucherOnly") == 1) {
        setPaymentMethod("voucher");
        navigate(`/${storeId}/form`, { state });
      } else {
        navigate(`/${storeId}/paymentMethods`);
      }
    }
  }, [state.clicked]);

  useEffect(() => {
    if (state.createImei) {
      fetch(`https://server.greenpanda.io/scripts/getBrands.php`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          data.push({ brand: "Other" });
          setBrands(data);
        })
        .catch((e) => {
          console.log("eskase", e);
          setLoading(false);
          setError(errorText);
        });
    } else if (!offer || offer === 0 || offer <=0) {
      setErrorMessage(errorjson["Price not found"]);
      navigate(`/${storeId}/error`);
    }
  }, []);

  function replaceSpecialChars(str) {
    str = str?.replace(/[-&\/\\#,+()$~%'"\s:*?<>{}]/g, "_");
    return str;
  }

  const onYouClick = () => {
    setPaymentMethod("voucher");
    fetch(`https://imei-service.greenpanda.io/offer`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        imei: state.imei,
        deviceType: state.deviceType,
        currencies: "EUR",
        partner: "yougr",
        promoCode: promoCode,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setYou(true);
        setFields(data);
        storeId === "gpscooter1"
          ? navigate(`/${storeId}/form/?store=0083`, { state })
          : navigate(`/${storeId}/form/?store=0086`, { state });
      });
  };
  const onSamsungClick = () => {
    navigate(`/${storeId}/paymentMethods/?store=0153`, { state });
  };

  const onXiaomiClick = () => {
    navigate(`/${storeId}/paymentMethods/?store=0156`, { state });
  };
  const onAnswerClick = () => {
    if (state.offer) {
      console.log("Changed", state.offer);
      if (imeiInfo && (imeiInfo.fmiOn || imeiInfo.fmiON)) {
        setErrorMessage(errorjson["Find my iPhone is on"]);
        navigate(`/${storeId}/error`);
      }
      if (localStorage.getItem("voucherOnly") == 1) {
        setPaymentMethod("voucher");
        navigate(`/${storeId}/form`, { state });
      } else {
        navigate(`/${storeId}/paymentMethods`);
      }
    }
  };

  const checkEnterKey = (e) => {
    if (e.key === "Enter" && promoCode !== "") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleInput = (e) => {
    if (!couponApplied) {
      setPromoCode(e);
      if (e === "") {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  };

  const setFields = (deviceInfo) => {
    setImeiInfo(deviceInfo);
    setDisabled(true);
    setDisableText(true);
    setValid(true);
    setInvalid(false);
    setOffer(deviceInfo.price + deviceInfo.promo);
    setCouponApplied(true);
    setPromoAmount(deviceInfo.promo);
    setPromo(promoCode, deviceInfo.promo, deviceInfo.price + deviceInfo.promo);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!couponApplied) {
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      if (state.createImei) {
        let couponPartner;
        if (state.imei.charAt() === "S") {
          couponPartner = "samsunggr";
        } else if (state.imei.charAt() === "X") {
          couponPartner = "xiaomigr";
        }
        fetch(
          `https://imei-service.greenpanda.io/promos?code=${promoCode}&partner=${couponPartner}`
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.result === "success") {
              if (couponPartner === "samsunggr") {
                setSamsung(true);
              } else if (couponPartner === "xiaomigr") {
                setXiaomi(true);
              }
              console.log(data);
              let deviceInfo = state.imeiInfo;
              deviceInfo.promo = data.amount;
              deviceInfo.localPromo[0].price = data.amount;
              setFields(deviceInfo);
            } else {
              if (data.result === "unknown_promo") {
                setInvalid(true);
                setValid(false);
              } else if (data.result === "promo_already_used") {
                setAlreadyUsed(true);
              } else {
                setError(errorjson["genericError"]);
                console.log(data.description);
              }
            }
          })
          .catch((e) => {
            console.log("eskase", e);
            setLoading(false);
            setError(errorText);
          });
      } else {
        let promises = [
          fetch(`https://imei-service.greenpanda.io/offer`, {
            method: "post",
            headers: headers,
            body: JSON.stringify({
              imei: state.imei,
              deviceType: state.deviceType,
              currencies: "EUR",
              partner: partner,
              promoCode: promoCode,
            }),
          }),
        ];
        if (storeId.includes("scooter")) {
          promises.push(
            fetch(`https://imei-service.greenpanda.io/offer`, {
              method: "post",
              headers: headers,
              body: JSON.stringify({
                imei: state.imei,
                deviceType: state.deviceType,
                currencies: "EUR",
                partner: "yougr",
                promoCode: promoCode,
              }),
            }),
            fetch(`https://imei-service.greenpanda.io/offer`, {
              method: "post",
              headers: headers,
              body: JSON.stringify({
                imei: state.imei,
                deviceType: state.deviceType,
                currencies: "EUR",
                partner: "samsunggr",
                promoCode: promoCode,
              }),
            }),
            fetch(`https://imei-service.greenpanda.io/offer`, {
              method: "post",
              headers: headers,
              body: JSON.stringify({
                imei: state.imei,
                deviceType: state.deviceType,
                currencies: "EUR",
                partner: "xiaomigr",
                promoCode: promoCode,
              }),
            })
          );
        }
        Promise.all(promises)
          .then((responses) => {
            return Promise.all(
              responses.map((response) => {
                return response.json();
              })
            );
          })
          .then((data) => {
            console.log(data);
            let pandas, you, samsung, xiaomi;
            if (storeId.includes("scooter")) {
              [pandas, you, samsung, xiaomi] = data;
            } else {
              pandas = data[0];
              you = { error: "unknown_promo" };
              samsung = { error: "unknown_promo" };
              xiaomi = { error: "unknown_promo" };
            }
            setLoading(false);
            if (
              pandas.error === "unknown_promo" &&
              you.error === "unknown_promo" &&
              samsung.error === "unknown_promo" &&
              xiaomi.error === "unknown_promo"
            ) {
              setInvalid(true);
              setValid(false);
            } else if (
              pandas.error === "promo_already_used" ||
              you.error === "promo_already_used" ||
              samsung.error === "promo_already_used" ||
              xiaomi.error === "promo_already_used"
            ) {
              setAlreadyUsed(true);
              console.log("already_used");
            } else if (
              pandas.error &&
              you.error &&
              samsung.error &&
              xiaomi.error
            ) {
              for (let i = 0; i < data.length; i++) {
                if (data[i].error != "unknown_promo") {
                  setError(errorjson[data[i].error]);
                }
              }
            } else {
              let deviceInfo;
              if (samsung.promo) {
                deviceInfo = samsung;
                setSamsung(true);
              } else if (you.promo) {
                deviceInfo = you;
                setYou(true);
              } else if (pandas.promo) {
                deviceInfo = pandas;
                setPandas(true);
              } else {
                deviceInfo = xiaomi;
                setXiaomi(true);
              }
              setFields(deviceInfo);
              console.log(deviceInfo);
            }
          })
          .catch((e) => {
            console.log("eskase", e);
            setLoading(false);
            setError(errorText);
          });
      }
    }
  };

  // console.log(state)
  console.log(imeiInfo);

  return (
    <>
      {!offer ? (
        <Redirect to={`/${storeId}`} />
      ) : (
        <PageContainer hasExit>
          <Row style={{ width: "100%", maxWidth: "1051px", marginTop: "50px" }}>
            <BackButton
              onClick={() => {
                navigate(`/${storeId}/conditions`);
              }}
            >
              {"<Back"}
            </BackButton>
            <Row
              className="justify-content-center d-flex"
              style={{ width: "100%", marginBottom: "80px" }}
            >
              <Col
                xs="6"
                className="p-0"
                style={{ marginRight: "0px", marginTop: "40px" }}
              >
                {console.log(imeiInfo)}
                <div style={{ maxWidth: "270px", margin: "20px auto 0" }}>
                  {imeiInfo.brand === "Apple" && (
                    <img
                      src={
                        `${CDN}/devices/` +
                        imeiInfo.brand.toLowerCase() +
                        "/" +
                        modelImage +
                        ".jpg"
                      }
                      onError={(e) => {
                        e.target.src = `${CDN}/devices/phone.png`;
                        e.target.onError = null;
                      }}
                      width="100%"
                      alt="phone"
                    />
                  )}
                  {imeiInfo.brand !== "Apple" && (
                    <img
                      src={
                        `${CDN}/devices/` +
                        imeiInfo.brand.toLowerCase() +
                        "/" +
                        modelImage +
                        ".png"
                      }
                      onError={(e) => {
                        e.target.src = `${CDN}/devices/phone.png`;
                        e.target.onError = null;
                      }}
                      width="100%"
                      alt="phone"
                    />
                  )}
                </div>
              </Col>
              <Col xs="6" className="p-0" style={{ marginLeft: "0px" }}>
                <p
                  style={{
                    opacity: "30%",
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "36px",
                    marginBottom: "0px",
                  }}
                >
                  {imeiInfo.brand}
                </p>
                <h1
                  style={{
                    fontSize: "28px",
                    fontWeight: "700",
                    marginBottom: "40px",
                  }}
                >
                  {imeiInfo.model}{" "}
                  <span style={{ fontWeight: "300" }}>
                    {imeiInfo?.capacity}
                  </span>
                </h1>
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    opacity: "30%",
                    marginBottom: "5px",
                  }}
                >
                  VOUCHER
                </p>
                <h2
                  style={{
                    fontSize: "56px",
                    fontWeight: "800",
                    letterSpacing: "-1.5px",
                  }}
                >
                  {offer}zł
                </h2>
                <p style={{ marginTop: "50px", opacity: "30%" }}>
                  Enter promo code
                </p>
                <Col xs="12" className="p-0">
                  <input
                    className="couponInput"
                    disabled={disableText}
                    type="text"
                    style={{ marginRight: "30px" }}
                    placeholder="Enter promo code..."
                    onChange={(e) => handleInput(e.target.value)}
                    onKeyPress={(e) => {
                      checkEnterKey(e);
                    }}
                  />
                  <input
                    className="couponButton"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={disabled}
                    value="Apply code"
                  />
                </Col>
                <Button
                  onClick={onAnswerClick}
                  disabled={loading}
                  style={{ fontSize: "23px", margin: "70px 0 0px 0" }}
                  className="primaryButton button"
                >
                  {loading && <Spinner animation="border" className="mr-3" />}
                  Issue Voucher
                </Button>
              </Col>
            </Row>
            <Row
              className="justify-content-center align-items-center d-flex"
              style={{ width: "100%", marginBottom: "120px" }}
            >
              <div
                style={{
                  borderBottom: "2px solid #000000",
                  width: "100%",
                  marginBottom: "30px",
                }}
              >
                <h3
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    marginBottom: "20px",
                  }}
                >
                  Device Report
                </h3>
              </div>
              <div className="d-flex" style={{ width: "100%" }}>
                <div style={{ width: "50%", marginRight: "20px" }}>
                  <h4
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      opacity: "30%",
                      marginBottom: "20px",
                    }}
                  >
                    SELF ASSESMENT
                  </h4>
                  {state.assessmentResults.map((result, index) => {
                    return (
                      <div
                        className="d-flex justify-content-between"
                        key={index + result}
                        style={{
                          borderBottom: "2px solid #f2f2f2",
                          padding: "20px 0",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            style={{ marginRight: "10px" }}
                            src={CDN + `/tradein-app/${result.code}.svg`}
                            alt="condition"
                            width="30"
                            height="30"
                          />
                          <p className="m-0 pl-2">
                            {t(`conditions.${result.code}`)}
                          </p>
                        </div>
                        <div
                          style={{
                            textTransform: "capitalize",
                            fontWeight: "700",
                          }}
                        >
                          {result.condition === 1 ? (
                            <p className="m-0 pl-2">Works</p>
                          ) : (
                            <p className="m-0 pl-2" style={{color: 'rgba(255, 75, 85, 1)'}}>Doesn't work</p>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div style={{ width: "50%", marginLeft: "20px" }}>
                  <h4
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      opacity: "30%",
                      marginBottom: "20px",
                    }}
                  >
                    OPTICAL CHECK
                  </h4>
                  {state.partConditions.map((part, index) => {
                    return (
                      <div
                        className="d-flex justify-content-between"
                        key={part + index}
                        style={{
                          borderBottom: "2px solid #f2f2f2",
                          padding: "20px 0",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            style={{ marginRight: "10px" }}
                            src={CDN + `/tradein-app/${part.code}.svg`}
                            alt="condition"
                            width="30"
                            height="30"
                          />
                          <p className="m-0 pl-2">
                            {t(`conditions.${part.code}`)}
                          </p>
                        </div>
                        <div
                          style={{
                            textTransform: "capitalize",
                            fontWeight: "700",
                          }}
                        >
                          {part.condition === "100-96" && (
                            <p
                              className="m-0"
                              style={{  }}
                            >
                              {part.condition}
                            </p>
                          )}
                          {part.condition === "95-87" && (
                            <p
                              className="m-0"
                              style={{ color: "rgba(204, 184, 0, 1)" }}
                            >
                              {part.condition}
                            </p>
                          )}
                          {part.condition === "Below 86" && (
                            <p
                              className="m-0"
                              style={{ color: "rgba(255,75,85,1)" }}
                            >
                              {part.condition}
                            </p>
                          )}
                          {part.condition === 'yes' && <p className="m-0">{part.condition}</p>}
                          {part.condition === 'no' && <p style={{color: 'rgba(255, 75, 85, 1)'}} className="m-0">{part.condition}</p>}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Row>
          </Row>
        </PageContainer>
      )}
    </>
  );
};

export default withTranslation()(Offer);
