import { withTranslation } from "react-i18next";
import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import styled from "styled-components";
import { navigate } from "@gatsbyjs/reach-router";
import StateContext from "../providers/state";
// import Button from "../components/button";
import { Button, Modal, Alert } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { Title } from "../components/typography";
import SignatureCanvas from "react-signature-canvas";
import PageContainer from "../containers/PageContainer";
import Barcode from "react-barcode";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import PrivacyPolicy from "./PrivacyPolicy";
import PrivacyPolicySamsung from "./PrivacyPolicySamsung";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "Signature",
  },
};

const OfferPill = styled.div`
  border-bottom: 2px solid #f2f2f2;
  padding: 0 10px 0 20px;
  text-align: center;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 95%;
  margin-bottom: 30px;
`;

const Price = styled.div`
  border: solid 4px #3ac93a;
  border-radius: 80px 80px;
  padding: 0 10px;
  margin-left: 30px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
  text-align: center;
`;

const Text = styled.div`
  margin-top: -12px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #263846;
`;

const TermsDiv = styled.div`
  text-decoration: none;
  color: #1a1a1a;
  border: 2px solid #e6e6e6;
  width: 100%;
  min-height: 64px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
`;

const Anchor = styled.a`
  color: #1a1a1a;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: #ff6600;
  }
`;

// const classNamedTitle = classNamed.h1`
//   color: #263846;
//   font-size: 45px;
//   font-weight: bold;
//   margin-bottom: 36px;
// `;
const ClearButton = styled.button`
  //   border: solid 4px #D6DFE6;
  font-weight: bold;
  //   border-radius: 30px 30px;
  // padding: 20px 25px;
  background-color: white;
  font-size: 15px;
  color: #263846;
  &:hover {
    cursor: pointer;
  }
  margin-bottom: -28px;
  z-index: 100;
`;

const BackButton = styled.div`
  position: absolute;
  left: 20px;
  font-size: 16px;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
`;

const Sign = ({ storeId, t, i18n }) => {
  TagManager.dataLayer(tagManagerArgs);
  const { state, setField } = useContext(StateContext);
  const { imei, imeiInfo, offer, paymentMethod } = state;
  const signatureEl = useRef(null);
  const [signtureError, setSigntureError] = useState(false);
  const [hasSigned, setHasSigned] = useState(true);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const store = urlParams.get("store")
    ? urlParams.get("store")
    : localStorage.getItem("store");
  const uuid = store + "-" + uuidv4();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [terms1, onTermsChange] = useState(false);
  const [terms2, onTermsChange2] = useState(false);
  const [paidClicked, setPaidClicked] = useState(false);

  const saveBarcode = () => {
    var s = new XMLSerializer().serializeToString(
      document.getElementsByTagName("svg")[1]
    );
    var res = "data:image/svg+xml;base64," + window.btoa(s);
    console.log(res);
    return res;
  };
  useEffect(() => {
    setField("signature", false);
    setField("barcode", saveBarcode());
  }, []);

  const onGetPaidClick = () => {
    setPaidClicked(true);
    console.log("CLICKED!");
    if (hasSigned) {
      setField(
        "signature",
        signatureEl.current.getTrimmedCanvas().toDataURL("image/png")
      );
      setLoading(true);

      setError(null);
      console.log("After setField", state.signature);
      console.log(
        signatureEl.current.getTrimmedCanvas().toDataURL("image/png")
      );

      if(state.offerForm.email){
        var templateData = {
          name: state.offerForm.name,
          code: state.imei,
          brand: state.imeiInfo.brand,
          model: state.imeiInfo.model,
          condition: state.condition,
          price: state.offer,
          gpPrice: state.offer,
          coupon: 0,
          start_date: new Date(),
          end_date: new Date(),
          email: state.offerForm.email,
          partner: state.imeiInfo.partnerInfo.partner,
          partner_name: "Orange",
          localCurrency: "zl",
          barcode: "",
        };
        var data = {
          email: state.offerForm.email,
          cc: "",
          templateName: "voucheren",
          templateData: JSON.stringify(templateData),
        };
        console.log("mailData", data);
  
        fetch(`https://communications.greenpanda.io/mail`, {
          method: "POST",
          cache: "no-cache",
          headers: { "Content-Type": "application/json" },
          referrerPolicy: "no-referrer",
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success === 1) {
              setLoading(false);
              console.log(data);
              navigate(`/thankyou`);
            } else {
              setLoading(false);
              setError("Please try again...");
            }
          })
          .catch((e) => {
            setLoading(false);
            setError("Please try again...");
          });
      }else{
        navigate(`/thankyou`);
      }

      
    }
  };

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  return (
    <>
      {console.log(state)}
      <PageContainer>
        <OfferPill>
          <BackButton
            onClick={() => {
              navigate(`/${storeId}/form`);
            }}
          >
            {"<Back"}
          </BackButton>
          {imeiInfo.name} - <b>Voucher {offer}zł</b>
        </OfferPill>
        <div
          style={{ width: "446px" }}
          className="d-flex flex-column justify-content-center"
        >
          <StyledTitle>{t("sign.title")}</StyledTitle>
          <div className="terms" onClick={() => onTermsChange(!terms1)}>
            <TermsDiv>
              {!terms1 && (
                <img
                  src="/checkbox_up.svg"
                  alt="checkbox"
                  width="20"
                  style={{ marginRight: "20px" }}
                />
              )}
              {terms1 && (
                <img
                  src={"/checkbox_down.svg"}
                  alt="checkbox"
                  width="20"
                  style={{ marginRight: "20px" }}
                />
              )}
              <Anchor onClick={handleShow}>{t("sign.terms")}</Anchor>
            </TermsDiv>
          </div>
          <div className="terms" onClick={() => onTermsChange2(!terms2)}>
            <TermsDiv>
              {!terms2 && (
                <img
                  src="/checkbox_up.svg"
                  alt="checkbox"
                  width="20"
                  style={{ marginRight: "20px" }}
                />
              )}
              {terms2 && (
                <img
                  src={"/checkbox_down.svg"}
                  alt="checkbox"
                  width="20"
                  style={{ marginRight: "20px" }}
                />
              )}
              <Anchor onClick={handleShow2}>{t("sign.terms2")}</Anchor>
            </TermsDiv>
          </div>
          {/* <ClearButton
            disabled={!hasSigned}
            onClick={() => {
              signatureEl && signatureEl.current.clear();
              setHasSigned(false);
            }}
          >
            Clear
          </ClearButton> */}
          <SignatureCanvas
            penColor="#1a1a1a"
            backgroundColor="#F7F7F7"
            canvasProps={{
              width: 446,
              height: 211,
              className: `mb-4 sigCanvas ${signtureError ? "sigError" : ""}`,
            }}
            ref={signatureEl}
            onBegin={() => setSigntureError(null)}
            onEnd={() => setHasSigned(true)}
          />
          <Button
            // disabled={
            //   !terms1 || !terms2 || !hasSigned || loading || paidClicked
            // }
            className="primaryButton button"
            type="submit"
            onClick={onGetPaidClick}
          >
            {loading && <Spinner animation="border" className="mr-3" />}
            {t("sign.cta")}
          </Button>
          {!!error && <Alert variant={"warning"}>{error}</Alert>}
          <div className="d-none">
            <Barcode value={imei} height={40} />
          </div>
        </div>
      </PageContainer>

      <Modal show={show} onHide={handleClose} className="my-modal">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {store != "0153" && <PrivacyPolicy />}
          {store == "0153" && <PrivacyPolicySamsung />}
        </Modal.Body>
        <Modal.Footer>
          <Button className="primaryButton " onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} className="my-modal">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <center>
              <p>
                <strong> Υπεύθυνη Δήλωση </strong> <br />
                <strong> (άρθρο 8 Ν.1599/1986) </strong>
              </p>
            </center>
            <p>
              Η ακρίβεια των στοιχείων που υποβάλλονται με αυτή τη δήλωση μπορεί
              να ελεγχθεί με βάση το αρχείο άλλων υπηρεσιών (άρθρο 8 παρ. 4 Ν.
              1599/1986).
            </p>
            <p>
              <strong>ΠΡΟΣ:</strong> Πάσα Αρμόδια Αρχή
            </p>
            <p>
              Με ατομική μου ευθύνη και γνωρίζοντας τις κυρώσεις, που
              προβλέπονται από τις διατάξεις της παρ. 6 του άρθρου 22 του Ν.
              1599/1986, ότι δηλαδή «[ό]ποιος εν γνώσει του δηλώνει ψευδή
              γεγονότα ή αρνείται ή αποκρύπτει τα αληθινά με έγγραφη υπεύθυνη
              δήλωση του άρθρου 8 τιμωρείται με φυλάκιση τουλάχιστον τριών
              μηνών. Εάν ο υπαίτιος αυτών των πράξεων σκόπευε να προσπορίσει
              στον εαυτόν του ή σε άλλον περιουσιακό όφελος βλάπτοντας τρίτον ή
              σκόπευε να βλάψει άλλον, τιμωρείται με κάθειρξη μέχρι 10 ετών»,
              δηλώνω ότι:
            </p>
            <p>
              Είμαι αδιαμφισβήτητος νόμιμος κύριος της συσκευής κινητού
              τηλεφώνου που πρόκειται να πουλήσω μέσω της υπηρεσίας "Green Panda
              trade-in App", η οποία περιήλθε στην κατοχή μου νομίμως δι’ αγοράς
              και παράδοσης της νομής και κατοχής της από εξουσιοδοτημένο
              πάροχο, κατάστημα κινητής τηλεφωνίας ή οποιονδήποτε άλλο φορέα
              διενεργεί νομίμως αγοραπωλησίες συσκευών κινητής τηλεφωνίας και
              διαθέτει όλες τις σχετικές προς τούτο άδειες.
            </p>
            <p>
              Η εν λόγω συσκευή κινητής τηλεφωνίας δεν συνιστά προϊόν κλοπής,
              υπεξαίρεσης, κλεπταποδοχής ή διάθεσης προϊόντων εγκλήματος,
              ληστείας ή οποιουδήποτε άλλου ποινικού αδικήματος ή παράνομης
              πράξης.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="primaryButton " onClick={handleClose2}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withTranslation()(Sign);
