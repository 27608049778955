import React, { useState, useCallback, useEffect } from "react";
import { Form, Alert, Spinner, Button } from "react-bootstrap";
import { navigate } from "@gatsbyjs/reach-router";
import PageContainer from "../containers/PageContainer";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "Login",
  },
};

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [input, setInput] = useState(localStorage.getItem("storeId"));
  const [storeData, setStoreData] = useState();

  TagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    const storeId = localStorage.getItem("storeId");
    console.log(storeId);
    if (storeId) navigate(`/${storeId}`);
  }, []);

  const handleUsernameSubmit = useCallback(
    (_storeId = undefined) => {
      setLoading(true);
      console.log("hi");
      const param = _storeId && typeof _storeId === "string" ? _storeId : input;
      fetch(`https://server.greenpanda.io/scripts/getUser.php?user=${param}`)
        .then((response) => response.json())
        .then((json) => {
          // console.log(json);
          if (!json) {
            setLoading(false);
            setInput("");
            setError("The username is wrong. Please try again");
            return;
          }
          setLoading(false);
          setStoreData(json);
          localStorage.setItem("storeId", input);
          localStorage.setItem("store", json.store);
          localStorage.setItem("partner", json.partner);
          localStorage.setItem("voucherOnly", json.voucherOnly);
          if (input.toLowerCase() === "wind1") {
            navigate(`/voucher`);
          } else {
            navigate(`/${input}`);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError("The username is wrong. Please try again");
          // console.log('error', error)
        });
    },
    [setLoading, input, setStoreData, setInput]
  );

  return (
    <>
      {process.env.REACT_APP_COMING_SOON && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            background: "black",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <h1 style={{ color: "#FF6600 " }}>
            <b>Coming Soon</b>
          </h1>
        </div>
      )}
      {!process.env.REACT_APP_COMING_SOON && (
        <PageContainer noExit>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleUsernameSubmit();
            }}
          >
            <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center" style={{backgroundColor: '#fff0e5', border: '1px solid #FF8648', borderRadius: '8px', padding: '10px 20px', marginBottom: '40px' }}>
                <div style={{ width: "24px" }}>
                  <img
                    src="https://cdn.greenpanda.io/tradein-app/info-orange.svg"
                    alt="info"
                    width="24"
                  />
                </div>
                <p style={{color: '#FF6A07', margin: '0 0 0 10px', fontSize: '16px'}}>Available on <b>tablet</b> and <b>desktop</b> devices</p>
              </div>
              </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center container"
              
            >
              <img
                src="https://cdn.greenpanda.io/tradein-app/user-circle-icon.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1668589947903"
                alt="userIcon"
              />
              <h5
                className="py-1"
                style={{ fontSize: "30px", fontWeight: "700" }}
              >
                Sign in
              </h5>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "#808080",
                }}
              >
                Type your store username
              </p>

              <Form.Group
                controlId="formBasicCheckbox"
                style={{ width: "100%", height: "64px", margin: "40px 0px" }}
              >
                <Form.Control
                  type="text"
                  name="storeId"
                  value={input || ""}
                  disabled={loading}
                  onChange={(e) => setInput(e.currentTarget.value)}
                  size="large"
                  style={{
                    fontSize: "25px",
                    textAlign: "center",
                    width: "100%",
                  }}
                />
              </Form.Group>
              {!!error && <Alert variant={"danger"}>{error}</Alert>}
              <Button
                className="primaryButton button"
                size="small"
                type="submit"
                disabled={loading}
                onClick={handleUsernameSubmit}
              >
                {loading && <Spinner animation="border" className="mr-3" />}
                <span>Login</span>
              </Button>
            </div>
          </Form>
        </PageContainer>
      )}
    </>
  );
};

export default Login;
