import React, { useEffect, useState, useContext } from "react";
import { navigate } from "@gatsbyjs/reach-router";
import styled from "styled-components";
import StateContext from "../../providers/state";
import Modal from "./modal";
import { withTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const INACTIVE_TIMEOUT = 200;

export const SHOW_MODAL_THRESHOLD = 30;

const Header = ({ children, location: { pathname }, storeId, t, i18n }) => {
  const { resetState } = useContext(StateContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [forceChange, triggerForceChange] = useState(false);
  const [counter, setCounter] = useState(INACTIVE_TIMEOUT);
  // const showExitBtn = pathname !== `/${storeId}`;

  let timer;

  const restartTimer = () => {
    if (timer) clearInterval(timer);
    setCounter(INACTIVE_TIMEOUT);
    setIsModalVisible(false);
    triggerForceChange(!forceChange);
  };

  const endTimer = () => {
    resetState();
    navigate(`/${storeId}`);
  };

  useEffect(() => {
    restartTimer();
  }, [pathname]);

  useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    if (counter <= SHOW_MODAL_THRESHOLD && !isModalVisible) {
      setIsModalVisible(true);
    }

    if (
      !counter &&
      pathname !== `/${storeId}` &&
      pathname !== `/${storeId}/thank-you`
    ) {
      endTimer();
    }

    return () => clearInterval(timer);
  }, [counter, forceChange]);

  // const handleExitClick = useCallback(() => {
  //   // resetState();
  //   navigate(`/${storeId}/thank-you`);
  // }, []);

  return (
    <Container onClick={restartTimer}>
      {isModalVisible && (
        <Modal
          visible={isModalVisible}
          onOk={restartTimer}
          onCancel={restartTimer}
          storeId={storeId}
          title={t("timer.title")}
          text={t("timer.info")}
        />
      )}
      <div style={{ right: "10px", position: "absolute", zIndex: "10" }}>
        {counter}
      </div>
      <div style={{ width: "100vw", height: "100vh" }}>{children}</div>
    </Container>
  );
};

export default withTranslation()(Header);
