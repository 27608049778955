import React from "react";
import { Link } from "@gatsbyjs/reach-router";
import { Result } from "antd";
import PageContainer from "../containers/PageContainer";
import { useContext } from "react";
import StateContext from "../providers/state";

const Error = () => {
  const { state } = useContext(StateContext);
  const { errorMessage } = state;
  return (
    <PageContainer hasExit>
      <Result
        status="404"
        // title="Μη αποδεκτή συσκευή"
        subTitle={errorMessage}
        extra={
          <Link to="/" className="primaryButton button">
            Back
          </Link>
        }
      />
    </PageContainer>
  );
};

export default Error;
